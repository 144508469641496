import React, { useEffect, useMemo, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import qs from 'qs'
import { Props as FieldProps } from '@/components/molecules/field'
import { ScoutCard, Jobseeker, useCardStyles } from '@/components/molecules/scout-card'
import { SectionTitle } from '@/components/atoms/section-title'
import { EmptyPlaceholder } from '@/components/organisms/empty-placeholder'
import snakecaseKeys from 'snakecase-keys'
import useSWR from 'swr'
import { useSession } from '@/hooks/use-session'
import { useAppContext, useDispatch } from '@/store'
import { filterJobseekers } from '@/submodules/filter-jobseekers-by-graduation-date'

type Props = {
  filter: FieldProps[]
  onStartBulkChat: (callback: () => void) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    results: {
      display: 'grid',
      gap: `${theme.spacing(4)}px`,
      gridAutoFlow: 'row',
    },
    placeholderWrapper: {
      padding: '50px',
    },
    loadingWrapper: {
      display: 'flex',
      justifyContent: 'center',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
)

function paramsFromFilter(filter: FieldProps[], app): string {
  const params = {}
  if (app.state.selectedYear) params['academicYear'] = String(app.state.selectedYear)
  if (app.state.selectedDivision) params['schoolDivisionId'] = String(app.state.selectedDivision)

  filter.forEach((f) => {
    if (typeof f.value === 'boolean') {
      if (f.value === true) {
        params[f.name] = 1
      }
    } else if (Array.isArray(f.value) && f.value.length) {
      params[f.name] = f.value
    } else if (f.value?.length) {
      params[f.name] = f.value
    }
  })
  return qs.stringify(snakecaseKeys(params), { arrayFormat: 'brackets' })
}

// Note: BulkChat should be in the future refactored, so this doesn't have to be done re-use BulkChat feature
// This function has been made in order to avoid potential issues with the existing feature
function convertJobSeekersForBulkChat(jobseekers: Jobseeker[]) {
  return jobseekers.map((jobseeker) => {
    return {
      id: jobseeker.id,
      jobseekerId: jobseeker.id,
      jobseeker,
    }
  })
}

const ADMIN_SEARCH_ENDPOINT = '/admins/jobseekers/search'
const COMPANY_SEARCH_ENDPOINT = '/companies/jobseekers/search'
const PLACEHOLDER_TEXT = '条件に当てはまる学生が見つかりませんでした\n条件を変えて再度お試しください'

export const ScoutResults = (props: Props) => {
  const app = useAppContext()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { isAdmin } = useSession()
  const endpoint = isAdmin ? ADMIN_SEARCH_ENDPOINT : COMPANY_SEARCH_ENDPOINT
  const cardStyles = useCardStyles()
  const searchParams = useMemo(() => paramsFromFilter(props.filter, app), [
    props.filter,
    app.state.selectedYear,
    app.state.selectedDivision,
  ])
  const { data: jobseekers, isValidating } = useSWR(`${endpoint}?${searchParams}`, { revalidateOnFocus: false })

  const filteredJobseekers = useMemo(() => {
    if (jobseekers) {
      return filterJobseekers(jobseekers)
    }
    return []
  }, [jobseekers])

  const fetchingJobseekers = isValidating && !jobseekers
  const noJobseekers = !isValidating && (!jobseekers || jobseekers?.length === 0)

  const handleStartBulkChat = () => {
    dispatch({
      type: 'ADD_TO_BULK_CHAT',
      payload: {
        students: convertJobSeekersForBulkChat(jobseekers),
      },
    })
  }

  useEffect(() => {
    if (props.onStartBulkChat) {
      props.onStartBulkChat(handleStartBulkChat)
    }
  }, [filteredJobseekers])

  return (
    <div>
      <SectionTitle>検索結果：{filteredJobseekers ? filteredJobseekers.length : 0}件</SectionTitle>
      <div className={classes.results}>
        {filteredJobseekers?.map((jobseeker: Jobseeker) => (
          <ScoutCard key={jobseeker.id} jobseeker={jobseeker} styles={cardStyles} />
        ))}
        {fetchingJobseekers && (
          <div className={classes.loadingWrapper + ' ' + classes.placeholderWrapper}>
            <CircularProgress />
          </div>
        )}
        {noJobseekers && (
          <div className={classes.placeholderWrapper}>
            <EmptyPlaceholder bodyText={PLACEHOLDER_TEXT}></EmptyPlaceholder>
          </div>
        )}
      </div>
    </div>
  )
}
