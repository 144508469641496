import { parseISO } from 'date-fns'

enum SCHOOL_DIVISIONS {
  SECOND_GRADUATE = 8,
}

const getCutoffDate = (isSecondGraduate: boolean) => {
  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth() + 1

  let cutoffYear = currentMonth >= 5 ? currentYear : currentYear - 1

  if (isSecondGraduate) {
    cutoffYear -= 4
  }

  return new Date(cutoffYear, 2, 31)
}

export const filterJobseekers = (jobseekers: App.Jobseeker[]) => {
  return jobseekers.filter((jobseeker) => {
    const isSecondGraduate = jobseeker.schoolDivisionId === SCHOOL_DIVISIONS.SECOND_GRADUATE
    const cutoffDate = getCutoffDate(isSecondGraduate)

    if (!jobseeker.graduatedAt || jobseeker.graduatedAt === '0001-01-01T00:00:00Z') {
      return true
    }

    const gradDate = parseISO(jobseeker.graduatedAt)
    return gradDate > cutoffDate
  })
}
